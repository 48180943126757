import { useMemo } from 'react'

import { CustomComponentSectionName } from '../../__generated__/graphql/catalog/graphql'
import { CollectionViewHeroSection } from '../../brunswick/components/CollectionViewHeroSection'
import { AccordionView } from '../../brunswick/components/page-sections/AccordionView/AccordionView'
import { PageRichTextSection } from '../../brunswick/components/page-sections/PageRichTextSection'
import { PageTabSection } from '../../brunswick/components/page-sections/PageTabSection'
import { PageTestimonialSection } from '../../brunswick/components/page-sections/PageTestimonialSection'
import { RawHtmlSection } from '../../brunswick/components/page-sections/RawHtmlSection'
import { VideoSection } from '../../brunswick/components/page-sections/VideoSection'
import { PageSection as PageSectionModel, ProductGroupViewSection } from '../../types/graphql'

import { CollectionSection } from './CollectionSection'
import { StudentDiscount } from './CustomSections/StudentDiscount'
import { PageBannerSection } from './PageBannerSection'
import { PageEditorialCarouselSection } from './PageEditorialCarouselSection'
import { PageHeroSection } from './PageHeroSection'
import { PageInteractiveSlideSection } from './PageInteractiveSlideSection'
import { PageLinksSection } from './PageLinksSection'
import { PageSectionContext, PageSectionContextValue } from './PageSectionContext'
import { PageTileSection } from './PageTileSection'
import { PageTitleSection } from './PageTitleSection'
import { ProductRecommendationsSection } from './ProductRecommendationsSection'

const CustomComponentSection = (componentName: CustomComponentSectionName): JSX.Element | null => {
  if (componentName === 'STUDENT_DISCOUNT') {
    return <StudentDiscount />
  }
  return null
}

export type PageSectionProps = {
  /** The Contentful Gateway Model used to generate a Contentful Component Section. */
  sectionModel: PageSectionModel | ProductGroupViewSection
  /** The index defining what section of the page this is. Leave empty if you are unsure. First sections of the page get preloaded images. */
  index: number
}

function renderPageSection(
  props: PageSectionModel | ProductGroupViewSection,
  index: number
): JSX.Element | null {
  switch (props.__typename) {
    case 'AccordionView':
      return <AccordionView {...props} />
    case 'CollectionSection':
      return <CollectionSection {...props} />
    case 'CollectionViewHeroSection':
      return <CollectionViewHeroSection {...props} isCriticalImage={index === 0} />
    case 'PageBannerSection':
      return <PageBannerSection {...props} />
    case 'PageEditorialCarouselSection':
      return <PageEditorialCarouselSection {...props} />
    case 'PageHeroSection':
      return <PageHeroSection {...props} isCriticalImage={index === 0} />
    case 'PageInteractiveSlideSection':
      return <PageInteractiveSlideSection {...props} />
    case 'PageLinksSection':
      return <PageLinksSection {...props} />
    case 'PageTabSection':
      return <PageTabSection {...props} index={index} />
    case 'PageTestimonialSection':
      return <PageTestimonialSection {...props} />
    case 'PageTileSection':
      return <PageTileSection {...props} />
    case 'PageTitleSection':
      return <PageTitleSection {...props} />
    case 'ProductRecommendationsSection':
      return <ProductRecommendationsSection {...props} />
    case 'RawHtmlSection':
      return <RawHtmlSection {...props} />
    case 'RichTextSection':
      return <PageRichTextSection {...props} />
    case 'VideoSection':
      return <VideoSection {...props} isCriticalVideo={index === 0} />
    case 'CustomComponentSection':
      return CustomComponentSection(props.componentName)
    default:
      return null
  }
}

export const PageSection = ({ sectionModel, index }: PageSectionProps) => {
  const contextValue: PageSectionContextValue = useMemo(
    () => ({
      pageSectionIndex: index,
    }),
    [index]
  )

  return (
    <PageSectionContext.Provider value={contextValue}>
      {renderPageSection(sectionModel, index)}
    </PageSectionContext.Provider>
  )
}
