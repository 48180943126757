import styled from 'styled-components'

import { ImageShrink, ResponsiveImage, TypeStyle } from '@syconium/little-miss-figgy'

import {
  PageTileSectionTextAlign,
  PageTileSectionTextPlacement,
} from '../../../../types/graphql/contentful-components/page-tile-section'

export const Image = styled(ResponsiveImage)``
export const InlineVideo = styled.video``
export const HoverImage = styled(ResponsiveImage)``
export const HoverVideo = styled.video``

export const TitleImage = styled(ImageShrink)`
  display: inline-block;
  max-width: 100%;
`

export const JumboTitle = styled(TypeStyle.HeadlineSmall)`
  display: inline-block;
`

export const Name = styled(TypeStyle.HeadlineExtraSmall)`
  display: inline-block;
`

export const Description = styled(TypeStyle.BodyDefault)`
  display: inline-block;
`

export const Container = styled.div<{
  textAlign: PageTileSectionTextAlign
  hasHover: boolean
  hasVerticalMargin?: boolean
}>`
  position: relative;
  text-align: ${o => o.textAlign};
  padding: ${o => (o.hasVerticalMargin ? '2px 0' : '0')};

  ${o =>
    o.hasHover &&
    `
    &:hover ${Image},
    &:hover ${InlineVideo} {
      display: none;
    }
    `}
`

export const ImageWrap = styled.figure<{
  aspectRatio: number
  roundCorners?: boolean
}>`
  display: block;
  overflow: hidden;
  padding-top: ${o => (1 / o.aspectRatio) * 100}%;
  position: relative;
  width: 100%;
  border-radius: ${o => o.roundCorners && '6px'};

  & > img,
  & > video {
    height: 100%;
    left: 0;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    position: absolute;
    top: 0;
    width: 100%;
  }
`

// Negative bottom margin since design gap is to text baseline
export const TextBelow = styled.div<{
  textPlacement: PageTileSectionTextPlacement
}>`
  display: ${o => o.textPlacement !== 'below' && 'none'};
  margin: 12px auto 0;
  max-width: 88vw;
  width: 100%;
`

export const TextOverlay = styled.div<{
  textPlacement: PageTileSectionTextPlacement
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${o => (o.textPlacement === 'center' ? 'center' : 'space-between')};
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

export const TextOverlayTop = styled.div``

export const TextOverlayMiddle = styled.div`
  box-sizing: border-box;
  padding: 0 6%;
  width: 100%;
`

export const TextOverlayBottom = styled.div<{
  textPlacement: PageTileSectionTextPlacement
}>`
  background: ${o => o.textPlacement === 'bottom' && 'rgba(255, 255, 255, 0.6)'};
  box-sizing: border-box;
  height: ${o => o.textPlacement === 'below' && '0'};
  overflow: hidden;
  padding: 0 6%;
  width: 100%;
`

export const CtaLink = styled.a`
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 23px;
  margin: 12px auto;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  &::after {
    border-bottom: 2px solid currentColor;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    width: 100%;
  }
`
