import pluralize from 'pluralize'

import { useTranslation } from '../../../../app/_providers/TranslationProvider.client'

export function useViewModel({ colorCount }: { colorCount: number }) {
  const { magnolia: strings } = useTranslation()

  return {
    colorCountText: pluralize(strings.productCarouselColorCount, colorCount),
  }
}
