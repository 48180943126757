import styled from 'styled-components'

import { ResponsiveImage, TypeStyle } from '@syconium/little-miss-figgy'

import { useTranslation } from '../../../app/_providers/TranslationProvider.client'

export const IdMeSection = () => {
  const { studentPage: strings } = useTranslation()

  return (
    <Container>
      <Header>{strings.header}</Header>
      <SectionWrap>
        <Section>
          <Image
            src='//cdn.shopify.com/s/files/1/0139/8942/files/get-discount-1.png?v=1619130968'
            aspectRatios={{
              sm: 1,
              md: 1,
            }}
            loading='lazy'
            alt='Select the student discount link above the Gift Card/Discount Code link'
            widths={{ unit: 'vw', sm: 100, md: 50 }}
          />
          <IndicatorOne />

          <Title>{strings.title1}</Title>

          <TypeStyle.BodySmall>{strings.instructions1}</TypeStyle.BodySmall>
        </Section>
        <Section>
          <Image
            src='https://cdn.shopify.com/s/files/1/0139/8942/files/get-discount-2_776f388e-1b10-467d-a690-b14a1d5690f2.png?v=1641946614'
            aspectRatios={{
              sm: 1,
              md: 1,
            }}
            loading='lazy'
            alt='Select the option that best describes you either as Military or Student'
            widths={{ unit: 'vw', sm: 100, md: 50 }}
          />
          <IndicatorTwo />

          <Title>{strings.title2}</Title>
          <TypeStyle.BodySmall>{strings.instructions2}</TypeStyle.BodySmall>
        </Section>
      </SectionWrap>

      <Disclaimer>{strings.disclaimer}</Disclaimer>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const SectionWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const Section = styled.div`
  width: 320px;
  position: relative;
  margin: 0 ${o => o.theme.spacing(5)};
`
const Image = styled(ResponsiveImage)`
  border-radius: ${o => o.theme.spacing(2.5)};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
`

const SelectionIndicator = styled.div`
  border: 2px solid #3278f0;
  box-sizing: border-box;
  position: absolute;
  border-radius: ${o => o.theme.spacing(1)};
`

const IndicatorOne = styled(SelectionIndicator)`
  top: 182px;
  left: 25px;
  height: 25px;
  width: 268px;
`

const IndicatorTwo = styled(SelectionIndicator)`
  top: 177px;
  left: 45px;
  height: 25px;
  width: 240px;
`

const Header = styled.h1`
  ${TypeStyle.css.headlineMedium}
  margin-bottom: ${o => o.theme.spacing(5)};
  text-transform: uppercase;
`

const Title = styled.h2`
  margin-top: ${o => o.theme.spacing(5)};
  ${TypeStyle.css.headlineExtraExtraSmall}
  text-transform: uppercase;
`

const Disclaimer = styled.div`
  ${TypeStyle.css.bodyNano}
  margin: ${o => o.theme.spacing(10)};
  max-width: 640px;
`
