import React, { useCallback } from 'react'

import {
  OpenVideoModalTextButton,
  Plus,
  ResponsiveImage,
  TypeStyleBodyDefault,
  lgEndPixels,
  smEndPixels,
} from '@syconium/little-miss-figgy'
import { Button } from '@syconium/little-miss-figgy/dist/components/Button'
import { VideoModal } from '@syconium/magnolia/src/brunswick/components/VideoModal'
import { VimeoVideo } from '@syconium/magnolia/src/brunswick/components/VimeoVideo'
import { usePageSectionContext } from '@syconium/magnolia/src/components/page-sections/PageSectionContext'
import { MaybeSpaLinkWrapper } from '@syconium/magnolia/src/lib/adapters/next-routing-service/MaybeSpaLinkWrapper'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isUppercase } from '@syconium/magnolia/src/lib/utils'
import { InlineVideoPlatforms } from '@syconium/magnolia/src/types/video'

import { MaxIterationsVideo } from '../../../app/_components/media/MaxIterationsVideo.client'
import { ContentfulStyledTextTitle } from '../../../app/_components/pages/contentful/components/ContentfulStyledText.client'
import {
  ContentfulPageBannerSectionAssetOverlayedContent,
  ContentfulPageBannerSectionAssetWrapper,
  ContentfulPageBannerSectionBackground,
  ContentfulPageBannerSectionButtonsDeprecated,
  ContentfulPageBannerSectionContent,
  ContentfulPageBannerSectionContentDeprecated,
  ContentfulPageBannerSectionEyebrowDeprecated,
  ContentfulPageBannerSectionHotspot,
  ContentfulPageBannerSectionLinksDeprecated,
  ContentfulPageBannerSectionTitleImageDeprecated,
  ContentfulPageBannerSectionVideoDialogButtonWrapper,
} from '../../../app/_components/pages/contentful/sections/ContentfulPageBannerSection.client'
import { useTranslation } from '../../../app/_providers/TranslationProvider.client'
import { BasePageSection } from '../../../brunswick/components/page-sections/styles'
import { MaybeSpaLink } from '../../../lib/adapters/next-routing-service/MaybeSpaLink'
import { useResponsiveVideoSrc } from '../../../lib/hooks/useResponsiveVideoSrc'
import { PlayButton } from '../../../lib/shared-styles'
import {
  PageBannerSection as IProps,
  PageBannerSectionTextPlacementDesktop,
  StyledContentItem,
} from '../../../types/graphql/contentful-components/page-banner-section'
import { ContentfulStyledImage } from '../../contentful/ContentfulStyledImage/ContentfulStyledImage'
import { ContentfulStyledText } from '../../contentful/ContentfulStyledText/ContentfulStyledText'

const Hotspot: React.FC<{ linkTo: string }> = ({ linkTo }) => {
  return (
    <MaybeSpaLinkWrapper href={linkTo}>
      <ContentfulPageBannerSectionHotspot href={linkTo}>
        <Plus />
      </ContentfulPageBannerSectionHotspot>
    </MaybeSpaLinkWrapper>
  )
}

export const BannerImage: React.FC<{
  altText: string
  hotspot: Readonly<{ linkHref1: string }> | null
  imageDesktop: string | null
  imageMobile: string | null
  shouldShowHotspot?: boolean
  textPlacementDesktop: PageBannerSectionTextPlacementDesktop
}> = ({ altText, hotspot, imageDesktop, imageMobile, shouldShowHotspot, textPlacementDesktop }) => {
  const src = imageDesktop ?? imageMobile

  const srcs =
    imageDesktop && imageMobile
      ? {
          sm: imageMobile,
          md: imageDesktop,
        }
      : undefined

  if (!src) return null
  return (
    <>
      <ResponsiveImage
        alt={altText}
        aspectRatios={{
          sm: null,
          md: null,
        }}
        src={src}
        srcs={srcs}
        loading={'lazy'}
        widths={{
          unit: 'vw',
          sm: 100,
          md: textPlacementDesktop === 'center' ? 100 : 50,
        }}
      />

      {shouldShowHotspot && <Hotspot linkTo={hotspot!.linkHref1} />}
    </>
  )
}

export const PageBannerSection: React.FC<IProps> = ({
  ctaStyle,
  backgroundColorDesktop,
  backgroundColorMobile,
  eyebrow,
  hotspot,
  imageDesktop,
  imageMobile,
  inlineVideoDesktop,
  inlineVideoMobile,
  inlineVimeoIdDesktop,
  inlineVimeoIdMobile,
  isFullBleedDesktop,
  linkHref1,
  linkHref2,
  linkText1,
  linkText2,
  modalVideoId,
  modalVideoPlatform,
  name,
  overlayColorDesktop,
  overlayColorMobile,
  paragraph,
  textAlignDesktop,
  textAlignMobile,
  textColorDesktop,
  textColorMobile,
  textContentMaxWidthDesktop,
  textContentMaxWidthMobile,
  textPlacementDesktop,
  textPlacementMobile,
  title,
  titleTextSize,
  titleImage,
  titleImageAspectRatio,
  titleImageMaxWidthDesktop,
  titleImageMaxWidthMobile,
  styledContentItems,
  hasVerticalMargin,
  openVideoModalText,
}) => {
  const {
    magnolia: { general },
  } = useTranslation()
  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()

  const hasModalVideo = Boolean(modalVideoId && modalVideoPlatform)
  const isTallAssetMobile = Boolean(inlineVideoMobile)
  linkHref1 = linkHref1 ?? ''
  linkHref2 = linkHref2 ?? ''

  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState<boolean>(false)

  const openVideoModal = React.useCallback(() => {
    if (hasModalVideo) setIsVideoModalOpen(true)
  }, [hasModalVideo])

  const closeVideoModal = React.useCallback(() => {
    setIsVideoModalOpen(false)
  }, [])

  const altText = title ?? paragraph ?? ''

  const shouldUseDisplayImages =
    !(inlineVideoDesktop || inlineVimeoIdDesktop) || !(inlineVideoMobile || inlineVimeoIdMobile)

  const video = useResponsiveVideoSrc({
    inlineVideoDesktop,
    inlineVideoMobile,
    inlineVimeoIdDesktop,
    inlineVimeoIdMobile,
  })

  const shouldShowHotspot: boolean =
    Boolean(hotspot?.linkHref1) &&
    (textPlacementDesktop === 'right' ||
      textPlacementDesktop === 'left' ||
      textPlacementMobile === 'below')

  const useNewContentFields = !!styledContentItems.length
  const lastContentItem = styledContentItems.at(-1)
  const renderStyledContentItems = useCallback(
    (props: StyledContentItem, index: number, numberOfItems: number): JSX.Element | null => {
      const key: string = `${props.id}-${index}`
      const isFirstStyledTextItem =
        styledContentItems.findIndex(o => o.__typename === 'StyledText') === index

      switch (props.__typename) {
        case 'StyledText':
          return (
            <ContentfulStyledText
              key={key}
              {...props}
              centerFloatContent
              defaultMaxWidth={450}
              defaultBottomMargin={index < numberOfItems - 1 ? 24 : 0}
              additionalCustomContent={
                isFirstStyledTextItem && hasModalVideo ? (
                  !openVideoModalText ? (
                    <ContentfulPageBannerSectionVideoDialogButtonWrapper
                      $location='text'
                      $spacing='more'
                      $textPlacementMobile={textPlacementMobile}
                      className='configurable-width'
                    >
                      <PlayButton
                        {...trackEvent({
                          category: 'page-banner-section',
                          action: 'click open video modal',
                          pageSectionName: title ?? name,
                          contentfulName: name,
                          pageSectionIndex,
                          correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                        })}
                        onClick={openVideoModal}
                      />
                    </ContentfulPageBannerSectionVideoDialogButtonWrapper>
                  ) : (
                    <ContentfulPageBannerSectionVideoDialogButtonWrapper
                      $location='text'
                      $spacing='less'
                      $textPlacementMobile={textPlacementMobile}
                      className='configurable-width'
                    >
                      <OpenVideoModalTextButton
                        {...trackEvent({
                          category: 'page-banner-section',
                          action: 'click open video modal',
                          pageSectionName: title ?? name,
                          contentfulName: name,
                          pageSectionIndex,
                          correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                        })}
                        text={openVideoModalText}
                        onClick={openVideoModal}
                      />
                    </ContentfulPageBannerSectionVideoDialogButtonWrapper>
                  )
                ) : null
              }
            />
          )
        case 'StyledImage':
          return (
            <ContentfulStyledImage
              key={key}
              {...props}
              transparentBackground
              widths={
                props.maxWidthMobile || props.maxWidthDesktop
                  ? {
                      unit: 'px',
                      sm: props.maxWidthMobile ?? smEndPixels,
                      md: props.maxWidthDesktop ?? lgEndPixels,
                    }
                  : { unit: 'vw', md: textPlacementDesktop === 'center' ? 100 : 50, sm: 100 }
              }
            />
          )
        default:
          return null
      }
    },
    [
      styledContentItems,
      hasModalVideo,
      openVideoModalText,
      textPlacementMobile,
      title,
      name,
      pageSectionIndex,
      imageDesktop,
      titleImage,
      openVideoModal,
      textPlacementDesktop,
    ]
  )

  return (
    <BasePageSection
      isFullBleedDesktop={isFullBleedDesktop}
      hasVerticalMargin={hasVerticalMargin}
      {...trackEvent({
        category: 'page-banner-section',
        action: 'any',
        pageSectionName: title ?? name,
        contentfulName: name,
        pageSectionIndex,
        correspondingAsset: imageDesktop ?? titleImage ?? undefined,
      })}
    >
      <ContentfulPageBannerSectionBackground
        data-testid='page-banner-section-body'
        $backgroundColorDesktop={backgroundColorDesktop}
        $backgroundColorMobile={backgroundColorMobile}
        $textPlacementDesktop={textPlacementDesktop}
        $textPlacementMobile={textPlacementMobile}
      >
        <ContentfulPageBannerSectionAssetWrapper
          $isFullBleedDesktop={isFullBleedDesktop}
          $isTallAssetMobile={isTallAssetMobile}
          $overlayColorDesktop={overlayColorDesktop}
          $overlayColorMobile={overlayColorMobile}
          $textPlacementDesktop={textPlacementDesktop}
          $textPlacementMobile={textPlacementMobile}
        >
          <ContentfulPageBannerSectionAssetOverlayedContent>
            {titleImage && (
              <ContentfulPageBannerSectionTitleImageDeprecated
                alt={altText}
                loading='lazy'
                src={titleImage}
                widths={
                  titleImageMaxWidthMobile || titleImageMaxWidthDesktop
                    ? {
                        unit: 'px',
                        sm: titleImageMaxWidthMobile ?? smEndPixels,
                        md: titleImageMaxWidthDesktop ?? lgEndPixels,
                      }
                    : { unit: 'vw', md: textPlacementDesktop === 'center' ? 100 : 50, sm: 100 }
                }
                aspectRatios={{
                  sm: titleImageAspectRatio,
                  md: titleImageAspectRatio,
                }}
                $location='asset'
                $textPlacementMobile={textPlacementMobile}
                $titleImageMaxWidthDesktop={titleImageMaxWidthDesktop}
                $titleImageMaxWidthMobile={titleImageMaxWidthMobile}
              />
            )}

            {hasModalVideo ? (
              !openVideoModalText ? (
                <ContentfulPageBannerSectionVideoDialogButtonWrapper
                  $location='asset'
                  $spacing='more'
                  $textPlacementMobile={textPlacementMobile}
                  className='configurable-width'
                >
                  <PlayButton
                    onClick={openVideoModal}
                    {...trackEvent({
                      category: 'page-banner-section',
                      action: 'click open video modal',
                      pageSectionName: title ?? name,
                      contentfulName: name,
                      pageSectionIndex,
                      correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                    })}
                  />
                </ContentfulPageBannerSectionVideoDialogButtonWrapper>
              ) : (
                <ContentfulPageBannerSectionVideoDialogButtonWrapper
                  $location='asset'
                  $spacing='less'
                  $textPlacementMobile={textPlacementMobile}
                  className='configurable-width'
                >
                  <OpenVideoModalTextButton
                    {...trackEvent({
                      category: 'page-banner-section',
                      action: 'click open video modal',
                      pageSectionName: title ?? name,
                      contentfulName: name,
                      pageSectionIndex,
                      correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                    })}
                    text={openVideoModalText}
                    onClick={openVideoModal}
                  />
                </ContentfulPageBannerSectionVideoDialogButtonWrapper>
              )
            ) : null}
          </ContentfulPageBannerSectionAssetOverlayedContent>

          {video?.platform === InlineVideoPlatforms.DEFAULT_PLAYER && (
            <MaxIterationsVideo
              autoPlay
              isLazy
              loop
              maxIterations={5}
              muted
              playsInline
              src={video.src}
              aspectRatios={null}
            />
          )}

          {video?.platform === InlineVideoPlatforms.VIMEO && (
            <VimeoVideo
              playText={general.playVideo}
              pauseText={general.pauseVideo}
              autoplay={true}
              includePauseButton={true}
              lazy
              thumbnail={
                <BannerImage
                  altText={altText}
                  hotspot={hotspot}
                  imageDesktop={imageDesktop}
                  imageMobile={imageMobile}
                  shouldShowHotspot={shouldShowHotspot}
                  textPlacementDesktop={textPlacementDesktop}
                />
              }
              videoSrc={video.src}
            />
          )}

          {shouldUseDisplayImages ? (
            <BannerImage
              {...{
                altText,
                hotspot,
                imageDesktop,
                imageMobile,
                shouldShowHotspot,
                textPlacementDesktop,
              }}
            />
          ) : null}

          {shouldShowHotspot && hotspot?.linkHref1 ? (
            <ContentfulPageBannerSectionHotspot href={hotspot.linkHref1}>
              <Plus />
            </ContentfulPageBannerSectionHotspot>
          ) : null}
        </ContentfulPageBannerSectionAssetWrapper>

        {useNewContentFields ? (
          <ContentfulPageBannerSectionContent
            $textPlacementDesktop={textPlacementDesktop}
            $textPlacementMobile={textPlacementMobile}
            $endsInLink={
              lastContentItem?.__typename === 'StyledText' && lastContentItem?.links.length > 0
            }
          >
            {styledContentItems.map((item, index, array) =>
              renderStyledContentItems(item, index, array.length)
            )}
          </ContentfulPageBannerSectionContent>
        ) : (
          <ContentfulPageBannerSectionContentDeprecated
            $textColorDesktop={textColorDesktop}
            $textColorMobile={textColorMobile}
            $textPlacementDesktop={textPlacementDesktop}
            $textPlacementMobile={textPlacementMobile}
            $textContentMaxWidthDesktop={textContentMaxWidthDesktop}
            $textContentMaxWidthMobile={textContentMaxWidthMobile}
            $textAlignMobile={textAlignMobile}
            $textAlignDesktop={textAlignDesktop}
          >
            {eyebrow && (
              <ContentfulPageBannerSectionEyebrowDeprecated className='configurable-width'>
                {eyebrow}
              </ContentfulPageBannerSectionEyebrowDeprecated>
            )}

            {titleImage && (
              <ContentfulPageBannerSectionTitleImageDeprecated
                alt={altText}
                loading='lazy'
                src={titleImage}
                widths={
                  titleImageMaxWidthMobile || titleImageMaxWidthDesktop
                    ? {
                        unit: 'px',
                        sm: titleImageMaxWidthMobile ?? smEndPixels,
                        md: titleImageMaxWidthDesktop ?? lgEndPixels,
                      }
                    : { unit: 'vw', md: textPlacementDesktop === 'center' ? 100 : 50, sm: 100 }
                }
                aspectRatios={{
                  sm: titleImageAspectRatio,
                  md: titleImageAspectRatio,
                }}
                $location='text'
                $textPlacementMobile={textPlacementMobile}
                $titleImageMaxWidthDesktop={titleImageMaxWidthDesktop}
                $titleImageMaxWidthMobile={titleImageMaxWidthMobile}
              />
            )}

            {title && (
              <ContentfulStyledTextTitle
                className='configurable-width'
                titleTextSize={titleTextSize}
                isUppercase={isUppercase(title)}
                includeBottomMargin={true}
                textAlignMobile={textAlignMobile}
                textAlignDesktop={textAlignDesktop}
              >
                {title}
              </ContentfulStyledTextTitle>
            )}

            {paragraph && (
              <TypeStyleBodyDefault asTag='p' className='configurable-width'>
                {paragraph}
              </TypeStyleBodyDefault>
            )}

            {hasModalVideo ? (
              !openVideoModalText ? (
                <ContentfulPageBannerSectionVideoDialogButtonWrapper
                  $location='text'
                  $spacing='more'
                  $textPlacementMobile={textPlacementMobile}
                  className='configurable-width'
                >
                  <PlayButton
                    {...trackEvent({
                      category: 'page-banner-section',
                      action: 'click open video modal',
                      pageSectionName: title ?? name,
                      contentfulName: name,
                      pageSectionIndex,
                      correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                    })}
                    onClick={openVideoModal}
                  />
                </ContentfulPageBannerSectionVideoDialogButtonWrapper>
              ) : (
                <ContentfulPageBannerSectionVideoDialogButtonWrapper
                  $location='text'
                  $spacing='less'
                  $textPlacementMobile={textPlacementMobile}
                  className='configurable-width'
                >
                  <OpenVideoModalTextButton
                    {...trackEvent({
                      category: 'page-banner-section',
                      action: 'click open video modal',
                      contentfulName: name,
                      pageSectionName: title ?? name,
                      pageSectionIndex,
                      correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                    })}
                    text={openVideoModalText}
                    onClick={openVideoModal}
                  />
                </ContentfulPageBannerSectionVideoDialogButtonWrapper>
              )
            ) : null}

            {ctaStyle === 'link' && linkHref1 && linkText1 && (
              <ContentfulPageBannerSectionLinksDeprecated
                $textAlignMobile={textAlignMobile}
                $textAlignDesktop={textAlignDesktop}
                className='configurable-width'
              >
                <MaybeSpaLink
                  href={linkHref1}
                  {...trackEvent({
                    category: 'page-banner-section',
                    action: 'click cta 1',
                    label: linkText1,
                    value: linkHref1,
                    contentfulName: name,
                    pageSectionName: title ?? name,
                    pageSectionIndex,
                    correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                  })}
                >
                  {linkText1}
                </MaybeSpaLink>

                {linkHref2 && linkText2 && (
                  <MaybeSpaLink
                    href={linkHref2}
                    {...trackEvent({
                      category: 'page-banner-section',
                      action: 'click cta 2 ',
                      label: linkText2,
                      value: linkHref2,
                      contentfulName: name,
                      pageSectionName: title ?? name,
                      pageSectionIndex,
                      correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                    })}
                  >
                    {linkText2}
                  </MaybeSpaLink>
                )}
              </ContentfulPageBannerSectionLinksDeprecated>
            )}

            {ctaStyle === 'button' && linkHref1 && linkText1 && (
              <ContentfulPageBannerSectionButtonsDeprecated>
                <MaybeSpaLinkWrapper
                  href={linkHref1}
                  {...trackEvent({
                    category: 'page-banner-section',
                    action: 'click cta 1',
                    label: linkText1,
                    value: linkHref1,
                    contentfulName: name,
                    pageSectionName: title ?? name,
                    pageSectionIndex,
                    correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                  })}
                >
                  <Button as='a' href={linkHref1}>
                    {linkText1}
                  </Button>
                </MaybeSpaLinkWrapper>
                {linkHref2 && linkText2 && (
                  <MaybeSpaLinkWrapper
                    href={linkHref2}
                    {...trackEvent({
                      category: 'page-banner-section',
                      action: 'click cta 2',
                      label: linkText2,
                      value: linkHref2,
                      pageSectionName: title ?? name,
                      contentfulName: name,
                      pageSectionIndex,
                      correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                    })}
                  >
                    <Button as='a' href={linkHref2}>
                      {linkText2}
                    </Button>
                  </MaybeSpaLinkWrapper>
                )}
              </ContentfulPageBannerSectionButtonsDeprecated>
            )}
          </ContentfulPageBannerSectionContentDeprecated>
        )}

        {hasModalVideo && (
          <VideoModal
            isOpen={isVideoModalOpen}
            onClose={closeVideoModal}
            videoId={modalVideoId}
            videoPlatform={modalVideoPlatform}
          />
        )}
      </ContentfulPageBannerSectionBackground>
    </BasePageSection>
  )
}
