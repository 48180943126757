import Link from 'next/link'
import { ComponentProps, FC } from 'react'

import { usePageSectionContext } from '@syconium/magnolia/src/components/page-sections/PageSectionContext'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { useTrackProductInteraction } from '../../../app/_providers/TrackingProvider.client'
import { CarouselProductSummary } from '../../../types/figs'
import { tileAspectRatioByLayoutType } from '../../constants'
import { TileLayout } from '../TileLayout'

import { ProductCarouselTile } from './ProductCarouselTile'

export const ProductCarousel: FC<{
  analyticsContext?: {
    category?: string
    customAttributes?: Record<string, string | undefined>
    pageSectionName?: string
  }
  augmentTileLinkPath?: (path: string) => string
  fullBleed?: ComponentProps<typeof TileLayout>['fullBleed']
  hideSectionSideGaps?: ComponentProps<typeof TileLayout>['hideSectionSideGaps']
  hideColors?: ComponentProps<typeof ProductCarouselTile>['hideColors']
  hidePrice?: ComponentProps<typeof ProductCarouselTile>['hidePrice']
  products: readonly CarouselProductSummary[]
  visibleTilesDesktop?: ComponentProps<typeof TileLayout>['visibleTilesDesktop']
  visibleTilesMobile?: ComponentProps<typeof TileLayout>['visibleTilesMobile']
  featuredImage?: ComponentProps<typeof TileLayout>['featuredImage']
  featuredVideo?: ComponentProps<typeof TileLayout>['featuredVideo']
}> = ({
  augmentTileLinkPath,
  analyticsContext,
  fullBleed = false,
  hideSectionSideGaps,
  hideColors,
  hidePrice,
  products,
  visibleTilesDesktop = 4,
  visibleTilesMobile = 2,
  featuredImage,
  featuredVideo,
}) => {
  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()

  const { trackProductInteraction } = useTrackProductInteraction()

  return (
    <TileLayout
      fullBleed={fullBleed}
      hideSectionSideGaps={hideSectionSideGaps}
      visibleTilesDesktop={visibleTilesDesktop}
      visibleTilesMobile={visibleTilesMobile}
      keyFromTile={(p, i) => `${p.id}-${i}`}
      featuredImage={featuredImage}
      featuredVideo={featuredVideo}
      layoutVariantDesktop='carousel'
      layoutVariantMobile='slider'
      liftedButtons
      renderTile={(product, { layoutVariant }, index) => {
        const basePath: string =
          product.category === 'Product Kit'
            ? `/kits/${product.productGroupHandle}`
            : `/products/${product.productGroupHandle}?color=${product.colorRawName}`
        const path = augmentTileLinkPath ? augmentTileLinkPath(basePath) : basePath
        const onProductView = () => {
          trackProductInteraction({
            handle: product.handle,
            shopifyId: product.shopifyId,
            id: product.id,
          })
        }
        return (
          <Link href={path} onClick={onProductView}>
            <ProductCarouselTile
              hideColors={hideColors}
              hidePrice={hidePrice}
              product={product}
              aspectRatio={tileAspectRatioByLayoutType[layoutVariant]}
              visibleTilesDesktop={visibleTilesDesktop}
              visibleTilesMobile={visibleTilesMobile}
              trackEvent={trackEvent({
                category: analyticsContext?.category ?? 'product-carousel-tile',
                customAttributes: analyticsContext?.customAttributes,
                action: 'click tile',
                label: `tile-${index + 1}`,
                value: product.title,
                pageSectionIndex: pageSectionIndex,
                pageSectionName: analyticsContext?.pageSectionName,
                correspondingAsset: product.image,
              })}
            />
          </Link>
        )
      }}
      tiles={products}
    />
  )
}
